import { Table, Select, Form, notification, Checkbox } from "antd";
import PtzCascader from "../../components/cam/cascader.ptz";
import MultiLevelSelect from "../../components/cam/multilevel.select";
import { useParams } from "react-router-dom";
import dahuaService from "../../services/dahua.service";
import { useEffect, useState } from "react";
import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { ISPCam } from "../../ducks/sp-cam/model";
import {
  IGroupInfoProps,
  IHubState,
  NotificationType,
} from "../../models/ajax";
import RenderHubState from "../../components/cam/RenderHubState";
import RenderGroupName from "../../components/cam/RenderGroupName";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import RenderCameraInfo from "../../components/cam/RenderCameraInfo";
import { DefaultOptionType } from "antd/es/select";

interface IPanelProps {
  camera: any;
  channels: any;
  activeChannel: any;
  control: any;

  statusCode?: number;
  message?: string;
}

interface IBindedCamDetail {
  SpAjaxUsersService: SpAjaxUsersService;
}

const deps: IBindedCamDetail = {
  SpAjaxUsersService: new SpAjaxUsersService(),
};

const GroupsTable = () => {
  const { id } = useParams();
  const [formChannels] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const cameraId = id;

  const [isLoading, setIsLoading] = useState(false);
  const [panel, setPanel] = useState([]);
  const [channels, setChannels] = useState([]);
  const [camera, setCamera] = useState<any>({});
  const [selectedRulesByGroup, setSelectedRulesByGroup] = useState<any>([]);

  const columns = [
    {
      title: "Состояние группы",
      dataIndex: "state",
      key: "state",
      width: 200,
      isEnabled: true,
      render: (state: IHubState, record: any) => (
        <RenderHubState state={state} />
      ),
    },
    {
      title: "Группа",
      dataIndex: "groupName",
      key: "groupName",
      isEnabled: true,
      width: 250,
      render: (groupName: string) => <RenderGroupName name={groupName} />,
    },
    {
      title: "Правила",
      dataIndex: "rules",
      key: "rules",
      width: 400,
      isEnabled: true,
      render: (rules: any, { key, groupName }: any) => {
        const selectedRuleValues =
          selectedRulesByGroup?.[key]?.map((rule: any) => rule.value) ?? [];

        return (
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <Form>
              <Form.Item>
                <Select
                  mode="multiple"
                  placeholder="Выберите IVS"
                  options={rules.map((rule: any) => ({
                    label: rule.label,
                    value: rule.value,
                    path: rule.path,
                  }))}
                  value={selectedRuleValues}
                  maxTagCount={"responsive"}
                  onChange={(_: any, option: any) =>
                    handleSelectRule(option, { key, groupName })
                  }
                  size="middle"
                />
              </Form.Item>
            </Form>
          </div>
        );
      },
    },
    {
      title: "PTZ Control",
      dataIndex: "multi",
      key: "multi",
      isEnabled: true,
      render: (multi: any, { key }: any) => {
        const selectedRuleIds =
          selectedRulesByGroup?.[key]?.map((rule: any) => rule.value) ?? [];

        return (
          <MultiLevelSelect
            options={multi || []}
            selectedIds={(ids) => handleIdsFromTree(ids, key)}
            selectedRulesIds={selectedRuleIds}
          />
        );
      },
    },
  ];

  const getCameraDetail = async () => {
    setIsLoading(true);

    try {
      const panel: IPanelProps = await dahuaService.getControlPanelInfo(
        cameraId as string
      );
      if (!!panel?.activeChannel) {
        setPanel(panel?.control);
      }

      if (panel.statusCode) {
        const openNotificationWithIcon = (type: NotificationType) => {
          api[type]({
            message: panel?.message,
          });
        };
        openNotificationWithIcon("error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const findAndSetActiveChannel = (channels: any[], activeChannel: string) => {
    const active = channels.find((ch) => ch?.idx === Number(activeChannel));

    if (active.idx) {
      formChannels.setFieldValue("selected", active);
    }
  };

  const getCameraAndChannel = async () => {
    const { channels, camera } = await dahuaService.getCameraChannels(
      id as string
    );
    setChannels(channels);
    setCamera(camera);
    setSelectedRulesByGroup(camera?.rules);

    if (!!camera?.activeChannel) {
      findAndSetActiveChannel(channels, camera?.activeChannel);
    }
  };

  const syncServices = async () => {
    await getCameraAndChannel();
    await getCameraDetail();
  };

  useEffect(() => {
    syncServices();
  }, []);

  const handleChangeChannel = async (value: number) => {
    const channel = channels.find((ch: any) => ch.idx === value);

    if (channel) {
      try {
        await dahuaService.updateCameraChannel(channel, id as string);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const FormChannel = () => (
    <Form
      form={formChannels}
      layout="vertical"
      name="form"
      style={{ width: "35%" }}
    >
      <Form.Item name="selected" label="Каналы">
        <Select
          onChange={handleChangeChannel}
          options={channels}
          value={camera?.activeChannel}
          loading={false}
          disabled={false}
        />
      </Form.Item>
    </Form>
  );

  const handleChangeArmingExecuteRule = async (rule: any) => {
    await dahuaService.updateCamera({
      ...camera,
      multiZone: {
        ...camera.multiZone,
        onArming: rule,
      },
    });
    window.location.reload();
  };

  const handleChangeDisarmedExecuteRule = async (rule: any) => {
    await dahuaService.updateCamera({
      ...camera,
      multiZone: {
        ...camera.multiZone,
        onDisarming: rule,
      },
    });
    window.location.reload();
  };

  const onChangeCheckboxPTZ = async (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    await dahuaService.updateCamera({
      ...camera,
      multiZone: {
        ...camera.multiZone,
        enabled: checked,
      },
    });

    window.location.reload();
  };

  const handleSelectRule = async (option: any, group: any) => {
    const rules = option.map((rule: any) => {
      return {
        enable: `VideoAnalyseRule${rule.path}.Enable=true`,
        disable: `VideoAnalyseRule${rule.path}.Enable=false`,
        value: rule.value,
      };
    });

    setSelectedRulesByGroup((prev: any) => ({
      ...prev,
      [group.key]: rules,
    }));

    const payload = {
      id: camera._id,
      groupId: group.key,
      rules: rules,
    };

    await dahuaService.updateCameraRules(payload);
  };

  const findRuleById = (id: any, key: string) => {
    const row: any = panel.find((p: any) => p.key === key);
    return row.rules.find((rule: any) => rule.value === String(id));
  };

  const handleIdsFromTree = async (ids: number[], groupKey: string) => {
    let rulesToUpdate = ids.map((id) => {
      const rule: any = findRuleById(id, groupKey);

      return {
        enable: `VideoAnalyseRule${rule.path}.Enable=true`,
        disable: `VideoAnalyseRule${rule.path}.Enable=false`,
        value: rule.value,
      };
    });

    const updatedSelectedRules = {
      ...selectedRulesByGroup,
      [groupKey]: rulesToUpdate,
    };

    setSelectedRulesByGroup(updatedSelectedRules);

    const payload = {
      id: camera._id,
      groupId: groupKey,
      rules: updatedSelectedRules[groupKey],
    };

    await dahuaService.updateCameraRules(payload);
  };

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        {camera?.ip && <RenderCameraInfo camera={camera} />}

        <FormChannel />

        <div style={{ marginTop: 30 }}>
          {!!camera?.multiZone && (
            <Checkbox
              onChange={onChangeCheckboxPTZ}
              checked={camera?.multiZone?.enabled}
              style={{ marginBottom: 10 }}
            >
              MultiZone
            </Checkbox>
          )}

          {!!camera?.multiZone?.enabled && (
            <PtzCascader
              tour={{
                tour: camera?.multiZone?.tour,
                tourMax: camera?.multiZone?.tourMax,
                tourMin: camera?.multiZone?.tourMin,
              }}
              preset={{
                preset: camera?.multiZone?.preset,
                presetMax: camera?.multiZone?.presetMax,
                presetMin: camera?.multiZone?.presetMin,
              }}
              autoScan={{
                autoScan: camera?.multiZone?.autoScan,
                autoScanMax: camera?.multiZone?.autoScanMax,
                autoScanMin: camera?.multiZone?.autoScanMin,
              }}
              pattern={{
                pattern: camera?.multiZone?.pattern,
                patternMax: camera?.multiZone?.patternMax,
                patternMin: camera?.multiZone?.patternMin,
              }}
              onArmingRule={handleChangeArmingExecuteRule}
              onDisarmingRule={handleChangeDisarmedExecuteRule}
              onArming={camera?.multiZone?.onArming}
              onDisarming={camera?.multiZone?.onDisarming}
            />
          )}
        </div>
      </div>

      <div>
        <Table dataSource={panel} columns={columns} loading={isLoading} />
      </div>
      {contextHolder}
    </>
  );
};

export default GroupsTable;
