import { IASIEntity } from "../../pages/client-hub-asi/components/model";
import { api } from "../../services/helpers";
import { HttpService } from "../http/HttpService";

const httpService = new HttpService(`${api}`);
const url = "sp-asi-devices";

export class ASIDeviceService {
  async create(payload: IASIEntity): Promise<any> {
    return await httpService.post(`/${url}`, payload);
  }

  async getList(hubId: string): Promise<any> {
    return await httpService.post(`/${url}/get-list`, { hubId });
  }

  async getPanelInfo(payload: any) {
    return await httpService.post(`/${url}/panel`, {
      hubId: payload.hubId,
      asiId: payload.asiId,
    });
  }
}
