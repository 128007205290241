import { ISpaceDetail } from "../../models/space";
import { api } from "../../services/helpers";
import { HttpService } from "../http/HttpService";

const httpService = new HttpService(`${api}`);

export class HubLocationService {
  async getLocationDetail(spaceId: string): Promise<ISpaceDetail> {
    const url = `/client-space/id/${spaceId}`;
    return await httpService.get<ISpaceDetail>(url);
  }

  async getBindedHubIdInLocation(spaceId: string, hubId: string): Promise<any> {
    return await httpService.post("/sp-ajax-hubs/get-binded-id", {
      spaceId,
      hubId,
    });
  }

  async getBindedHubsInLocation(spaceId: string): Promise<any> {
    return await httpService.post("/sp-ajax-hubs/get-binded", { spaceId });
  }

  async verifyIfBindedAjaxUser(hubId: string): Promise<boolean> {
    return await httpService.post("/sp-ajax-hubs/verify-bind", { hubId });
  }

  async bindAjaxUserInLocation(payload: any): Promise<any> {
    return await httpService.post("/sp-ajax-hubs/bind", payload);
  }

  async bindControllerInLocation(payload: any): Promise<any> {
    return await httpService.post("/sp-ajax-hubs/bind-controller", payload);
  }

  async getHubGroups(hubId: string): Promise<any> {
    return await httpService.post("/sp-ajax-hubs/get-groups", { hubId });
  }
}
