import { Button, Form, Input, Modal, notification } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import dahuaService from "../../services/dahua.service";

import { v4 as uuidv4 } from "uuid";
import { IUser } from "../../models/user";
import { HubLocationService } from "../../ducks/hub-location/hub.location";

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface AddCamToHubModalProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
  props?: any;
}

type NotificationType = "success" | "info" | "warning" | "error";

interface IAddCamToHubProps {
  hubId: string | undefined;
}

interface IBindCameraWithHub {
  hubLocationService: HubLocationService;
}

const deps: IBindCameraWithHub = {
  hubLocationService: new HubLocationService(),
};

const CheckIntroductionCamera: React.FC<AddCamToHubModalProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [successfullyAuth, setSuccessfullyAuth] = useState<boolean>(false);
  const [loadingCheck, setLoadingCheck] = useState<boolean>(false);
  const [errorCheck, setErrorCheck] = useState<boolean>(false);
  const [errorCheckText, setErrorCheckText] = useState<string>("");

  const verifyIntroductionCamera = async () => {
    try {
      await form.validateFields().then(async (value) => {
        setSuccessfullyAuth(false);
        setLoadingCheck(true);
        setErrorCheck(false);

        const response = await dahuaService.verifyAuthorize(value);

        if (response?.statusCode) {
          const status = response?.statusCode;
          setErrorCheckText(response.message);
          setErrorCheck(true);
          setLoadingCheck(false);

          const openNotificationWithIcon = (type: NotificationType) => {
            api[type]({
              message: response.message,
              description:
                status === 401
                  ? "Неверный логин или пароль"
                  : "Проверьте детали запроса",
            });
          };
          openNotificationWithIcon("error");
        }

        if (response?.data) {
          setLoadingCheck(false);
          setSuccessfullyAuth(true);

          const openNotificationWithIcon = (type: NotificationType) => {
            api[type]({
              message: "Successfully verified CCTV",
              description: `SN - ${response?.data || "Неизвестно"}`,
            });
          };
          openNotificationWithIcon("success");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Добавить камеру к хабу"
        okText="Создать"
        cancelText="Отменить"
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setErrorCheck(false);
              setConfirmLoading(true);
              onCreate(values);

              setTimeout(() => {
                setConfirmLoading(false);
                form.resetFields();
              }, 2000);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
              setErrorCheck(true);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{}}
          size="small"
        >
          <Form.Item
            name="login"
            label="Логин"
            rules={[
              {
                required: true,
                message: "Логин обязателен!",
              },
            ]}
            required={true}
          >
            <Input type="text" placeholder="admin" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Пароль"
            rules={[
              {
                required: true,
                message: "Пароль обязателен!",
              },
            ]}
          >
            <Input.Password placeholder="Введите пароль" />
          </Form.Item>

          <Form.Item
            name="ip"
            label="ИП"
            rules={[
              {
                required: true,
                message: "ИП обязателен!",
              },
            ]}
          >
            <Input type="text" placeholder="Введите ip-адрес" />
          </Form.Item>

          <Form.Item
            name="port"
            label="Порт"
            rules={[
              {
                required: true,
                message: "Порт обязателен!",
              },
            ]}
          >
            <Input type="number" placeholder="Введите порт" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Описание"
            rules={[
              {
                required: true,
                message: "Описание обязательно!",
              },
            ]}
          >
            <Input type="text" placeholder="Ворота дома ..." />
          </Form.Item>

          <Button loading={loadingCheck} onClick={verifyIntroductionCamera}>
            Проверить соединение
          </Button>

          {errorCheck && (
            <Button danger style={{ marginLeft: 8 }}>
              {errorCheckText}
            </Button>
          )}

          {successfullyAuth && (
            <Button type="primary" style={{ marginLeft: 8 }}>
              Успешно
            </Button>
          )}
        </Form>
      </Modal>
    </>
  );
};

const AddCameraToHub = (props: IAddCamToHubProps) => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);

  const generatespaceId = () => {
    const stringURI = window.location.pathname;
    const delimiter = "/";
    const result = stringURI.split(delimiter);
    return result[2] || "";
  };

  const spaceId = generatespaceId();

  const addCameraToHub = async (values: Values) => {
    try {
      const camId = uuidv4();

      const hub = await deps.hubLocationService.getBindedHubIdInLocation(
        spaceId,
        props.hubId as string
      );

      await dahuaService.addCumToHub({
        ...values,
        action_by: user?.email,
        hubId: props.hubId,
        camId: camId,
        hubDocId: hub._id,
        spaceId: spaceId,
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <CheckIntroductionCamera
        open={open}
        onCreate={addCameraToHub}
        onCancel={() => setOpen(false)}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <Button type="primary" onClick={() => setOpen(true)}>
          Добавить камеру
        </Button>
      </div>
      {contextHolder}
    </div>
  );
};

export default AddCameraToHub;
