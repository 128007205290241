import { useParams } from "react-router-dom";

import AddASIDevice from "./components/add-asi";
import ASIDeviceList from "./components/asi-list";

export const ClientHubAsi = () => {
  const { id } = useParams();

  return (
    <div>
      <AddASIDevice hubId={id} />
      <ASIDeviceList hubId={id} />
    </div>
  );
};
