import { api } from "../../services/helpers";
import { HttpService } from "../http/HttpService";

const httpService = new HttpService(`${api}`);
const url = "sp-asi-users";

export class ASIUserService {
  async getPeoples(hubId: string): Promise<any> {
    return await httpService.get(`/${url}/people`);
  }

  async bindUsersToZone(payload: any) {
    return await httpService.post(`/${url}/people-bind`, payload);
  }
}
