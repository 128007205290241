import { Button, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ASIDeviceService } from "../../../ducks/asi-devices/asi-devices";

interface DataType {
  _id: string;
  deviceId: string;
  description: string;
  ip: string;
  port: number;
  login: string;
  password: string;
  hubId: string;
  spaceId: string;
  zoneIds: string[];
  heartbeat: number;
  createdAt: string;
  __v: 0;
}

const DeleteCameraComponent = (camId: any) => {
  const camService = new ASIDeviceService();

  const { id } = useParams();
  const hubId = id;

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      // const response = await camService.deleteCamera({ ...camId, hubId });

      setOpen(false);
      setConfirmLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setConfirmLoading(false);
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Удаление"
      description="Вы действительно хотите удалить камеру ?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading, danger: true }}
      onCancel={handleCancel}
      cancelText={"Отменить"}
      okText={"Удалить"}
    >
      <Button danger onClick={showPopconfirm}>
        Удалить
      </Button>
    </Popconfirm>
  );
};

const columns: ColumnsType<DataType> = [
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
    render: (_, cam) => String(cam.ip + ":" + cam.port),
  },
  /* {
    title: "Кол-во - правил",
    key: "rules",
    render: (_, cam) => cam.rules?.length,
  },
  {
    title: "Группа",
    key: "rules",
    render: (_, cam) => cam.group?.label || "Неопределено",
  }, */
  {
    title: "Действия",
    key: "action",
    render: (_, asi) => (
      <Space size="middle">
        <Link to={`/hub-asi/${asi._id}/asi-people/${asi.hubId}`}>
          Пользователи и зоны
        </Link>
        <DeleteCameraComponent camId={asi._id} hubId={asi.hubId} />
      </Space>
    ),
  },
];

interface IASIListProps {
  hubId: string | undefined;
}

const ASIDeviceList = (props: IASIListProps) => {
  const [asiList, setASIList] = useState([]);
  const asiService = new ASIDeviceService();

  const getASIList = async () => {
    try {
      const response = await asiService.getList(props.hubId as string);
      setASIList(response);
    } catch (error) {
      setASIList([]);
    }
  };

  useEffect(() => {
    getASIList();
  }, []);

  return (
    <div>
      {!!asiList.length && (
        <Table
          size="small"
          columns={columns}
          dataSource={asiList}
          rowKey={(i) => i.ip}
        />
      )}
    </div>
  );
};

export default ASIDeviceList;
