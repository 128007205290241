import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Button, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { SpAjaxUsersService } from "../../ducks/ajax-users/sp-ajax.users.service";
import { HubLocationService } from "../../ducks/hub-location/hub.location";
import { AjaxService } from "../../ducks/ajax/ajax.service";

import { IDeleteHubData, IHubSpaceListDataType } from "../../models/ajax";

interface ICreateUserProps {
  HubLocationService: HubLocationService;
  spAjaxUsersService: SpAjaxUsersService;
  ajaxService: AjaxService;
}

const deps: ICreateUserProps = {
  HubLocationService: new HubLocationService(),
  spAjaxUsersService: new SpAjaxUsersService(),
  ajaxService: new AjaxService(),
};

export const AjaxHubSpaceList = () => {
  const { id } = useParams();
  const [spaceHubList, setSpaceHub] = useState<IHubSpaceListDataType[]>();
  const [listLoading, setListLoading] = useState<boolean>(false);

  const getBindedSpAjaxHubs = async () => {
    setListLoading(true);
    if (id) {
      try {
        const bindedList =
          await deps.HubLocationService.getBindedHubsInLocation(id as string);

        const listOfHubDetailed = await deps.ajaxService.bindedHubDetailActual(
          bindedList
        );

        if (listOfHubDetailed?.length) {
          setSpaceHub(listOfHubDetailed);
        } else {
          setSpaceHub([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setListLoading(false);
      }
    }
  };

  const DeleteHubFromList = (deleteData: IDeleteHubData) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showPopconfirm = () => {
      setOpen(true);
    };

    const handleOk = async () => {
      setConfirmLoading(true);

      try {
        setListLoading(true);
        await deps.spAjaxUsersService.deleteSpBindedUserHub(deleteData);

        setOpen(false);
        setConfirmLoading(false);

        getBindedSpAjaxHubs();
        setListLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const handleCancel = () => {
      setConfirmLoading(false);
      setOpen(false);
    };

    return (
      <Popconfirm
        title="Удаление"
        description="Вы действительно хотите удалить Хаб и подключенные камеры ?"
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading, danger: true }}
        onCancel={handleCancel}
        cancelText={"Отменить"}
        okText={"Удалить"}
      >
        <Button danger onClick={showPopconfirm}>
          Удалить
        </Button>
      </Popconfirm>
    );
  };

  const columns: ColumnsType<IHubSpaceListDataType> = [
    {
      title: "Id",
      key: "hubId",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Battery",
      key: "battery",
      render: (_, hub) => (
        <div>
          {hub.battery && (
            <div>
              {hub.battery.state} - {hub.battery.chargeLevelPercentage}%
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Channel",
      key: "activeChannels",
      render: (_, hub) => (
        <div>
          {hub.activeChannels.length && (
            <div>{hub.activeChannels.join(", ")}</div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, hub) => {
        return (
          <Space size="middle">
            <Link to={`/space/${id}/hub-cam/${hub.id}`}>Камеры</Link>
            <Link to={`/space/${id}/hub-asi/${hub.id}`}>ASI</Link>
            <DeleteHubFromList hubId={hub.id} spaceId={id as string} />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    getBindedSpAjaxHubs();
  }, []);

  return (
    <div>
      <Table
        size="small"
        columns={columns}
        dataSource={spaceHubList}
        rowKey={(i) => i.id}
        loading={listLoading}
      />
    </div>
  );
};
