import { Button, Form, Input, Modal, notification, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import { HubLocationService } from "../../../ducks/hub-location/hub.location";
import { IUser } from "../../../models/user";
import dahuaService from "../../../services/dahua.service";
import { ASIDeviceService } from "../../../ducks/asi-devices/asi-devices";
import { IASIEntity } from "./model";

interface Values {
  ip: string;
  port: string;
  login: string;
  password: string;
  description: string;
  zones: string[];
}

interface AddASIServiceModalProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
  props?: any;
}

type NotificationType = "success" | "info" | "warning" | "error";

interface IAddCamToHubProps {
  hubId: string | undefined;
}

interface IBindASIWithHub {
  hubLocationService: HubLocationService;
  asiDevices: ASIDeviceService;
}

const deps: IBindASIWithHub = {
  hubLocationService: new HubLocationService(),
  asiDevices: new ASIDeviceService(),
};

const CheckIntroductionASI: React.FC<AddASIServiceModalProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const groups = [
    {
      id: "00000001",
      groupName: "Parking 1",
      state: "DISARMED",
    },
    {
      id: "00000002",
      groupName: "parking 2",
      state: "DISARMED",
    },
    {
      id: "00000003",
      groupName: "parking 3",
      state: "DISARMED",
    },
    {
      id: "00000004",
      groupName: "parking4",
      state: "DISARMED",
    },
    {
      id: "00000005",
      groupName: "Parking5",
      state: "DISARMED",
    },
    {
      id: "00000006",
      groupName: "parking6",
      state: "DISARMED",
    },
    {
      id: "00000007",
      groupName: "parking7",
      state: "DISARMED",
    },
    {
      id: "00000008",
      groupName: "parking8",
      state: "DISARMED",
    },
    {
      id: "00000009",
      groupName: "parking9",
      state: "DISARMED",
    },
  ];
  const [selectedZoneIds, setSelectedZoneIds] = useState<string[]>([]);

  const [api, contextHolder] = notification.useNotification();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [successfullyAuth, setSuccessfullyAuth] = useState<boolean>(false);
  const [loadingCheck, setLoadingCheck] = useState<boolean>(false);
  const [errorCheck, setErrorCheck] = useState<boolean>(false);
  const [errorCheckText, setErrorCheckText] = useState<string>("");

  const verifyIntroductionASI = async () => {
    try {
      await form.validateFields().then(async (value) => {
        setSuccessfullyAuth(false);
        setLoadingCheck(true);
        setErrorCheck(false);

        const response = await dahuaService.verifyAuthorize(value);

        if (response?.statusCode) {
          const status = response?.statusCode;
          setErrorCheckText(response.message);
          setErrorCheck(true);
          setLoadingCheck(false);

          const openNotificationWithIcon = (type: NotificationType) => {
            api[type]({
              message: response.message,
              description:
                status === 401
                  ? "Неверный логин или пароль"
                  : "Проверьте детали запроса",
            });
          };
          openNotificationWithIcon("error");
        }

        if (response?.data) {
          setLoadingCheck(false);
          setSuccessfullyAuth(true);

          const openNotificationWithIcon = (type: NotificationType) => {
            api[type]({
              message: "Успешно проверено",
              description: `SN - ${response?.data || "Неизвестно"}`,
            });
          };
          openNotificationWithIcon("success");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const selectAllZones = () => {
    const allZoneIds = groups.map((group) => group.id);

    const areAllSelected =
      selectedZoneIds.length === allZoneIds.length &&
      allZoneIds.every((id) => selectedZoneIds.includes(id));

    if (areAllSelected) {
      setSelectedZoneIds([]);
    } else {
      setSelectedZoneIds(allZoneIds);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        style={{ width: "100%" }}
        open={open}
        title="Добавить ASI к хабу"
        okText="Создать"
        cancelText="Отменить"
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setErrorCheck(false);
              setConfirmLoading(true);
              onCreate(values);

              setTimeout(() => {
                setConfirmLoading(false);
                form.resetFields();
              }, 2000);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
              setErrorCheck(true);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{}}
          size="small"
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 24 }}>
            <div style={{ flex: 1 }}>
              <Form.Item
                name="login"
                label="Логин"
                rules={[{ required: true, message: "Логин обязателен!" }]}
                required
              >
                <Input type="text" placeholder="admin" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Пароль"
                rules={[{ required: true, message: "Пароль обязателен!" }]}
              >
                <Input.Password placeholder="Введите пароль" />
              </Form.Item>

              <Form.Item
                name="ip"
                label="IP"
                rules={[{ required: true, message: "IP обязателен!" }]}
              >
                <Input type="text" placeholder="Введите IP-адрес" />
              </Form.Item>

              <Form.Item
                name="port"
                label="Порт"
                rules={[{ required: true, message: "Порт обязателен!" }]}
              >
                <Input type="number" placeholder="Введите порт" />
              </Form.Item>

              <Form.Item
                name="description"
                label="Описание"
                rules={[{ required: true, message: "Описание обязательно!" }]}
              >
                <Input type="text" placeholder="Ворота дома ..." />
              </Form.Item>

              <Button loading={loadingCheck} onClick={verifyIntroductionASI}>
                Проверить соединение
              </Button>

              {errorCheck && (
                <Button danger style={{ marginLeft: 8 }}>
                  {errorCheckText}
                </Button>
              )}

              {successfullyAuth && (
                <Button type="primary" style={{ marginTop: 8 }}>
                  Успешно
                </Button>
              )}
            </div>

            <div style={{ flex: 1 }}>
              {successfullyAuth && (
                <div>
                  <Button
                    type="primary"
                    onClick={selectAllZones}
                    size="small"
                    style={{ marginBottom: 8 }}
                  >
                    {selectedZoneIds?.length ? "Снять все" : "Отметить все"}
                  </Button>

                  <Form.Item name="zones">
                    <Select
                      style={{ width: "100%" }}
                      mode="multiple"
                      placeholder="Выберите зоны"
                      options={groups.map((group) => ({
                        label: group.groupName,
                        value: group.id,
                      }))}
                      value={selectedZoneIds}
                      maxTagCount="responsive"
                      onChange={(newZoneIds) => {
                        setSelectedZoneIds(newZoneIds);
                      }}
                      size="small"
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const AddASIDevice = (props: IAddCamToHubProps) => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);

  const getSpaceId = () => {
    const stringURI = window.location.pathname;
    const delimiter = "/";
    const result = stringURI.split(delimiter);
    return result[2] || "";
  };
  const spaceId = getSpaceId();
  const hubId = props.hubId;

  const addAsiToHub = async (values: Values) => {
    try {
      const { ip, port, login, password, description, zones } = values;
      const payload: IASIEntity = {
        ip: ip,
        port: Number(port),
        login: login,
        password: password,
        description: description,
        zoneIds: zones,
        hubId: hubId as string,
        spaceId: spaceId,
        deviceId: description,
      };

      await deps.asiDevices.create(payload);
    } catch (error) {
      console.log(error);
    }
  };

  // const getHubGroups = async () => {
  //   await deps.hubLocationService.getHubGroups(props.hubId as string);
  // };
  //
  // useEffect(() => {
  //   getHubGroups();
  // }, []);

  return (
    <div style={{ marginBottom: 16 }}>
      <CheckIntroductionASI
        open={open}
        onCreate={addAsiToHub}
        onCancel={() => setOpen(false)}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <Button type="primary" onClick={() => setOpen(true)}>
          Добавить ASI
        </Button>
      </div>

      {contextHolder}
    </div>
  );
};

export default AddASIDevice;
