import { useParams } from "react-router-dom";
import { HubLocationService } from "../../ducks/hub-location/hub.location";
import { ASIDeviceService } from "../../ducks/asi-devices/asi-devices";
import { ASIUserService } from "../../ducks/asi-users/asi-users.service";
import { Form, Select, Table } from "antd";

import RenderGroupName from "../../components/cam/RenderGroupName";
import { useEffect, useState } from "react";

interface IBindASIUsers {
  hubLocationService: HubLocationService;
  asiDevices: ASIDeviceService;
  asiUsers: ASIUserService;
}

const deps: IBindASIUsers = {
  hubLocationService: new HubLocationService(),
  asiDevices: new ASIDeviceService(),
  asiUsers: new ASIUserService(),
};

const UserHubASI = () => {
  const { id } = useParams();
  const [sourcePanel, setSourcePanel] = useState([]);
  const [isLoadingPanel, setIsLoadingPanel] = useState(false);

  const getDeviceId = () => {
    const stringURI = window.location.pathname;
    const delimiter = "/";
    const result = stringURI.split(delimiter);
    return result[2] || "";
  };
  const asiId = getDeviceId();

  const columns = [
    {
      title: "Группа",
      dataIndex: "groupName",
      key: "groupName",
      isEnabled: true,
      width: 250,
      render: (groupName: string) => <RenderGroupName name={groupName} />,
    },
    {
      title: "Пользователи",
      dataIndex: "people",
      key: "people",
      width: 400,
      isEnabled: true,
      render: (people: any[], record: any) => {
        const { key: zoneId, allUsers = [] } = record;

        const selectedValues = people?.map((p) => p.value || p.userId) ?? [];

        return (
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <Form>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  mode="multiple"
                  placeholder="Выберите пользователей"
                  options={allUsers.map((user: any) => ({
                    label: user.label || user.userName || user.userId,
                    value: user.value || user.userId,
                  }))}
                  value={selectedValues}
                  maxTagCount="responsive"
                  onChange={(selected: string[]) =>
                    handleAssignUsersToZone(selected, zoneId)
                  }
                  size="middle"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Form>
          </div>
        );
      },
    },
  ];

  const handleAssignUsersToZone = async (userIds: string[], zoneId: string) => {
    setSourcePanel((prev: any) =>
      prev.map((row: any) =>
        row.key === zoneId
          ? {
              ...row,
              people: row.allUsers.filter((user: any) =>
                userIds.includes(user.value || user.userId)
              ),
            }
          : row
      )
    );

    await deps.asiUsers.bindUsersToZone({
      asiId,
      zoneId,
      userIds,
    });
  };

  const fetchDataSource = async () => {
    setIsLoadingPanel(true);
    const source = await deps.asiDevices.getPanelInfo({
      hubId: id,
      asiId: asiId,
    });
    setSourcePanel(source as []);
    setIsLoadingPanel(false);
  };

  useEffect(() => {
    fetchDataSource();
  }, []);

  return (
    <div>
      <Table
        dataSource={sourcePanel}
        columns={columns}
        loading={isLoadingPanel}
      />
    </div>
  );
};

export default UserHubASI;
